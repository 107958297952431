<template>
  <button
      class="inline-flex items-center justify-center w-16 h-16 bg-white rounded-full focus:outline-none transition duration-300 ease-in-out"
      :class="{ 'bg-danger text-white': disabled }"
      @click="$emit('click', $event)"
  >
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.2021 14.1429V15.7608V15.9535V17.5714V21.5714C22.2021 21.7858 22.0236 21.9643 21.8093 21.9643H8.09501C7.88065 21.9643 7.70215 21.7858 7.70215 21.5714V10.1429C7.70215 9.9285 7.88065 9.75 8.09501 9.75H21.8093C22.0236 9.75 22.2021 9.9285 22.2021 10.1429V14.1429ZM23.4825 17.0411L22.2985 15.8571L23.4825 14.6732L26.7736 11.3821V20.3322L23.4825 17.0411Z" class="stroke-current" stroke-width="1.5"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'MuteVideo',
  props: ['disabled'],
};
</script>

<style lang="scss">

</style>
