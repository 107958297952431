<template>
  <p class="timer font-medium">{{ elapsedTime }}</p>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Timer',
  props: ['reservation'],
  computed: {
    elapsedTime() {
      // Horário marcado
      const horarioAgendado = dayjs(this.reservation.date);
      // Diferença entre agora e o horário marcado
      const diferenca = this.agora.diff(horarioAgendado, 'second');

      // Se a diferença é menor que 0, ainda não começou
      if (diferenca < 0) {
        return `Horário de início: ${horarioAgendado.format('DD/MM/YYYY HH:mm')}`;
      }

      // Se a diferenção for maior que 0, retorna os minutos
      return this.secondsToMinutesAndSeconds(diferenca);
    },
  },
  data() {
    return {
      agora: dayjs(),
      startTime: null,
      endTime: null,
    };
  },
  beforeDestroy() {
    this.stop();
  },
  watch: {
    reservation: {
      deep: true,
      handler() {
        this.stop();
        this.start();
      },
    },
  },
  methods: {
    start() {
      this.startTime = this.getStartTime();
      this.interval = setInterval(this.updateTime, 1000);
    },
    stop() {
      clearInterval(this.interval);
    },
    getStartTime() {
      const date = this.reservation.consultant_joined_at;
      if (!date) {
        return null;
      }

      return dayjs(date);
    },
    didDisconnect() {
      return !!this.reservation.consultant_disconnected_at;
    },
    updateTime() {
      if (this.didDisconnect()) {
        this.endTime = dayjs(this.reservation.consultant_disconnected_at);
        this.stop();
        return;
      }

      this.endTime = dayjs();

      // Faz com que o "elapsedTime" seja atualizado
      this.agora = dayjs();
    },
    secondsToMinutesAndSeconds(sec) {
      const minutes = Math.floor(sec / 60);
      const seconds = sec - minutes * 60;
      const time = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      return time;
    },
    millisToMinutesAndSeconds(millis) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },
};
</script>

<style lang="scss">

</style>
