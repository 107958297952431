<template>
  <button
      class="inline-flex items-center justify-center w-16 h-16 bg-white rounded-full focus:outline-none transition duration-300 ease-in-out"
      :class="{ 'bg-danger text-white': disabled }"
      @click="$emit('click', $event)"
  >
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9521 19C18.6121 19 19.9421 17.66 19.9421 16L19.9521 10C19.9521 8.34 18.6121 7 16.9521 7C15.2921 7 13.9521 8.34 13.9521 10V16C13.9521 17.66 15.2921 19 16.9521 19Z" class="stroke-current" stroke-width="1.5"/>
      <path d="M22.9701 16C22.5501 16 22.2001 16.3 22.1401 16.71C21.7701 19.32 19.4201 21.1 16.8901 21.1C14.3601 21.1 12.0101 19.33 11.6401 16.71C11.5801 16.3 11.2201 16 10.8101 16C10.2901 16 9.89012 16.46 9.96012 16.97C10.4201 19.94 12.9201 22.27 15.8901 22.72V25C15.8901 25.55 16.3401 26 16.8901 26C17.4401 26 17.8901 25.55 17.8901 25V22.72C20.8501 22.29 23.3601 19.94 23.8201 16.97C23.8901 16.46 23.4901 16 22.9701 16Z" class="fill-current" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'MuteAudio',
  props: ['disabled'],
};
</script>

<style lang="scss">

</style>
