<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12"
    width="16"
    height="12"
  >
    <path
      d="M2.85 6.82h12.42A.79.79 0 0016 6a.79.79 0 00-.73-.83H2.85l3.79-3.75A.83.83 0 105.47.24L.25 5.4a.82.82 0 000 1.17l5.21 5.18a.83.83 0 101.17-1.17z"
      fill="#00136f"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeft'
}
</script>

<style lang="scss">

</style>
