<template>
  <button class="inline-flex items-center justify-center w-16 h-16 bg-white rounded-full focus:outline-none bg-danger" @click="$emit('click', $event)">
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.1558 19.25L21.6158 18.96C21.0058 18.89 20.4058 19.1 19.9758 19.53L19.1358 20.37C16.3058 18.93 13.9858 16.62 12.5458 13.78L13.3958 12.93C13.8258 12.5 14.0358 11.9 13.9658 11.29L13.6758 8.77C13.5558 7.76 12.7058 7 11.6858 7H9.95582C8.82582 7 7.88582 7.94 7.95582 9.07C8.48582 17.61 15.3158 24.43 23.8458 24.96C24.9758 25.03 25.9158 24.09 25.9158 22.96V21.23C25.9258 20.22 25.1658 19.37 24.1558 19.25Z" stroke="white" stroke-width="1.5"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'Disconnect',
};
</script>

<style lang="scss">

</style>
