<template>
  <div class="call relative min-h-screen flex items-center justify-center" :class="{ 'call--joined': joined }">
    <header class="absolute top-0 left-0 w-full p-10 h">
      <router-link :to="{ name: 'Calendar' }" class="inline-block">
        <arrow-left />
      </router-link>
    </header>

    <div class="call-container container max-w-full flex flex-col lg:flex-row items-center">
      <div class="video-track relative md:mr-16 lg:mr-32">
        <div ref="remote" key="remote" v-if="joined" class="remote-video in-call-card"></div>
        <div ref="local" key="local" class="local-video call-card"></div>

        <div class="call-card-actions" v-if="!loading && joined">
          <mute-audio @click="muteAudio" :disabled="mutedAudio" />
          <disconnect @click="confirmDisconnect" />
          <mute-video @click="muteVideo" :disabled="mutedVideo" />
        </div>

        <p class="video-room-title" v-if="joined && loading">Carregando...</p>
        <p class="absolute z-30 top-0 inset-0 flex items-center justify-center text-white text-center p-5" v-if="totalParticipants === 0 && !loading && joined">
          <span>Aguardando <span class="underline">{{ reservation.user.first_name }}</span> entrar na chamada...</span>
        </p>

        <timer class="absolute top-0 left-0 text-white pl-5 pt-5" :reservation="reservation" v-if="joined" />
      </div>

      <div class="call-invite max-w-full mt-16 lg:mt-0" v-if="!joined">
        <div class="call-customer-avatar flex flex-col items-center">
          <avatar class="w-28 h-28 text-2xl leading-none pb-2" :image="reservation.user.avatar" :name="reservation.user.name" />

          <span class="call-timer relative z-10 block bg-salmon px-5 py-1 text-blue rounded-2xl -mt-6">
            <timer :reservation="reservation" />
          </span>
        </div>

        <div class="mt-8 text-center">
          <p class="text-lg text-blue mb-8">
            Sua conversa com <span class="underline">{{ reservation.user.name }}</span> está marcada para <span class="underline">{{ date.format('DD/MM [às] H:mm') }}</span>. Preparada?
          </p>

          <Button @click="join">Entrar na sala</Button>
        </div>
      </div>
    </div>

    <end-call-modal @confirm="disconnect" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import api from '@/utils/api.js';
import { mapMutations, mapState } from 'vuex';
import { createLocalTracks, connect, LocalDataTrack } from 'twilio-video';

import Button from '@/components/Button';
import Avatar from '@/components/Avatar';
import MuteAudio from '@/components/video/MuteAudio';
import MuteVideo from '@/components/video/MuteVideo';
import Disconnect from '@/components/video/Disconnect';
import Timer from '@/components/video/Timer';
import EndCallModal from '@/components/modals/EndCallModal';
import ArrowLeft from '@/components/ArrowLeft';

export default {
  name: 'Video',
  components: {
    ArrowLeft,
    Timer,
    EndCallModal,
    Disconnect,
    MuteAudio,
    MuteVideo,
    Avatar,
    Button
  },
  computed: {
    ...mapState(['hasUserModal', 'inCall']),
    roomName() {
      const prefix = process.env.VUE_APP_VIDEO_PREFIX || '';
      return `${prefix}agendamento-${this.$route.params.room}`;
    },
    date() {
      return dayjs(this.reservation.date);
    },
  },
  data() {
    return {
      joined: false,
      loading: false,
      reservation: {
        user: {}
      },
      localTrack: null,
      dataTrack: null,
      remoteTrack: null,
      activeRoom: null,
      previewTracks: null,
      mutedAudio: false,
      mutedVideo: false,
      totalParticipants: 0,
    }
  },
  beforeDestroy() {
    this.closeCall();
    this.closeUserModal();
    this.disconnect(false);
    window.removeEventListener('beforeunload', this.closeWindowDialog);
  },
  created() {
    window.addEventListener('beforeunload', this.closeWindowDialog);
  },
  mounted() {
    this.openCall();
    this.fetchReservation();
    this.createLocalTrack();
  },
  methods: {
    ...mapMutations(['openUserModal', 'closeUserModal', 'openCall', 'closeCall', 'openEndCallModal', 'setUserModalMeta', 'setReservationModalMeta']),

    async join() {
      this.joined = true;
      this.loading = true;
      this.openUserModal();

      let token = null;

      try {
        token = await this.getAccessToken();
      } catch (e) {
        this.$router.push({ name: 'Calendar' });
        this.$noty.error('Ops, essa videochamada não existe mais ou não está disponível para você.');
        return;
      }

      await this.createChat(token);
    },

    async fetchReservation() {
      const response = await api.get(`reservations/${this.$route.params.room}`);
      this.reservation = response.data;

      this.setUserModalMeta(this.reservation.user);
      this.setReservationModalMeta(this.reservation);
    },

    async getAccessToken() {
      const response = await api.get('twilio/token', {
        params: {
          room: this.roomName,
        },
      });

      return response.data.access_token;
    },

    // Trigger log events
    dispatchLog(message) {
      console.log('[VIDEO]', message);
    },

    attachTrack(track, container) {
      if (track) {
        container.appendChild(track.attach());
      }
    },
    detachTrack(track) {
      const attachedElements = track.detach();
      attachedElements.forEach((element) => element.remove());
    },

    trackPublished(publication, container) {
      this.attachTrack(publication.track, container);

      publication.on('subscribed', (track) => {
        this.attachTrack(track, container);
      });

      publication.on('unsubscribed', (track) => {
        this.detachTrack(track);
      });
    },

    // Attach the Participant's Tracks to the DOM.
    participantConnected(participant) {
      this.dispatchLog(`Joining: '${participant.identity}'`);

      const { tracks } = participant;
      const container = this.$refs.remote;

      this.totalParticipants += 1;

      tracks.forEach((publication) => {
        this.trackPublished(publication, container);
      });

      this.fetchReservation();
    },

    participantDisconnected(participant) {
      this.dispatchLog(`Participant '${participant.identity}' left the room`);
      this.totalParticipants -= 1;

      this.$noty.info(`O participante saiu da sala.`);
    },

    muteAudio() {
      if (!this.activeRoom) {
        return;
      }

      this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
        if (this.mutedAudio) {
          this.mutedAudio = false;
          publication.track.enable();
          this.dispatchLog('Unmuted audio.');
          return;
        }

        this.mutedAudio = true;
        publication.track.disable();
        this.dispatchLog('Muted audio.');
      });
    },

    muteVideo() {
      const localVideoTrack = this.localTrack.find(track => track.kind === 'video');

      if (!this.activeRoom) {
        return;
      }

      this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
        if (this.mutedVideo) {
          this.mutedVideo = false;
          publication.track.enable();
          localVideoTrack.enable();
          this.dispatchLog('Unmuted video.');
          return;
        }

        this.mutedVideo = true;
        publication.track.disable();
        localVideoTrack.disable();
        this.dispatchLog('Muted video.');
      });
    },

    async createLocalTrack() {
      if (this.localTrack) {
        return;
      }

      this.localTrack = await createLocalTracks({
        audio: true,
        video: {
          width: 540,
          height: 960
        },
      });

      const localVideoTrack = this.localTrack.find(track => track.kind === 'video');
      this.attachTrack(localVideoTrack, this.$refs.local);
    },

    async createChat(token) {
      this.markAsJoined();
      this.dataTrack = new LocalDataTrack();

      const connectOptions = {
        name: this.roomName,
        audio: true,
        video: {
          width: 1080,
          height: 1920
        },
        tracks: [...this.localTrack, this.dataTrack]
      };

      const room = await connect(token, connectOptions);
      this.dispatchLog(`Successfully joined room: ${this.roomName}`);

      // set active room
      this.activeRoom = room;
      this.loading = false;

      room.participants.forEach(this.participantConnected);
      this.totalParticipants = room.participants.size;

      // When a Participant joins the Room, log the event.
      room.on('participantConnected', this.participantConnected);

      // When a Participant leaves the Room, detach its Tracks.
      room.on('participantDisconnected', this.participantDisconnected);
    },

    async markAsJoined() {
      this.dispatchLog('Mark as joined.');
      await api.put(`reservations/${this.$route.params.room}/join`);
      await this.fetchReservation();
    },

    markAsDisconnected() {
      api.put(`reservations/${this.$route.params.room}/disconnect`);
    },

    disconnect(save = true) {
      if (this.activeRoom) {
        this.dispatchLog('Disconnected');
        this.dataTrack.send('end-session');
        this.activeRoom.disconnect();
        this.activeRoom = null;

        this.localTrack.forEach((track) => {
          track.mediaStreamTrack.stop();
        });

        if (save) {
          this.markAsDisconnected();
        }

        this.$router.push({ name: 'Calendar' });
      }
    },

    confirmDisconnect() {
      this.openEndCallModal();
    },

    closeWindowDialog(event) {
      if(this.reservation.disconnected_at == null) {
        event.preventDefault();
        event.returnValue = '';
        window.confirm('Sair do site?');
      }
    }
  }
};
</script>

<style lang="scss">
.has-user-modal .call {
  .user-modal {
    right: 0;
    left: auto;
    width: 560px;

    .user-modal-layer {
      opacity: 0;
      pointer-events: none;
    }

    .user-modal-close,
    .user-modal-actions {
      display: none;
    }

    .user-modal-notes--in-call {
      @apply pb-8;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -48px;
        z-index: -1;

        width: calc(100% + 96px);
        height: 100%;

        background-color: rgba(81, 103, 207, 0.1);
      }
    }
  }
}

.video-room-title {
  @apply absolute inset-x-0 bottom-0 z-10 p-3 text-white text-center;
}

.call-container {
  max-width: 1024px;
}

.local-video {
  @apply block relative max-w-full overflow-hidden rounded-lg bg-black;
  width: 525px;
  height: 350px;
  filter: drop-shadow(0px 4px 30px rgba(0, 19, 111, 0.12));

  video {
    @apply w-full h-full;
  }

  .call--joined & {
    @apply absolute;
    top: 25px;
    right: 25px;
    width: 90px;
    height: 120px;
  }
}

.remote-video {
  @apply block relative max-w-full overflow-hidden rounded-lg bg-black;
  width: 480px;
  height: 640px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0.05) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.7);
  box-shadow: 0 2px 20px rgba(0, 19, 111, 0.04);

  video {
    @apply w-full h-full;
  }
}

.call-card-button {
  box-shadow: 0 2px 20px rgba(0, 19, 111, 0.04);
}

.call-card-actions {
  @apply absolute z-50 flex items-center space-x-5;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
}

.call-invite {
  width: 340px;
}
</style>
