<template>
  <div class="end-call-modal fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-0">
    <span
      class="end-call-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer"
      @click="closeEndCallModal"
    ></span>

    <div class="end-call-modal__card bg-white p-10 opacity-0 rounded-lg">
      <p class="text-sm text-blue font-light mb-2">Oie, não custa nada perguntar 😉</p>
      <h3 class="text-blue font-semibold text-xl">Você deseja encerrar esse atendimento?</h3>

      <div class="End-call-modal__actions flex items-center justify-between mt-16">
        <Button light ghost @click="closeEndCallModal" class="mr-8">Não</Button>
        <Button @click="finishCall">Sim, podemos encerrar</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Button from '@/components/Button';

export default {
  name: 'EndCallModal',
  components: {
    Button,
  },
  methods: {
    ...mapMutations(['closeEndCallModal', 'closeCall', 'closeUserModal']),
    finishCall() {
      this.$emit('confirm');
      this.closeEndCallModal();
      this.closeCall();
      this.closeUserModal();
    }
  },
};
</script>

<style lang='scss'>
.has-end-call-modal {
  .end-call-modal {
    opacity: 1;
    pointer-events: auto;
  }

  .end-call-modal-layer {
    @apply opacity-30;
  }

  .end-call-modal__card {
    opacity: 1;
    transform: none;
  }
}

.end-call-modal {
  z-index: 100;
  bottom: 0;
  pointer-events: none;
  overflow-y: scroll;
  transition: opacity 0.3s, transform .3s;
}

.end-call-modal-layer {
  z-index: -1;
}

.end-call-modal__card {
  width: 540px;
  transform: scale(0.90);
  transition: transform .3s, opacity 0.3s;
}
</style>
